<template>
  <div id="doScroll" class="scrollbar docscroll l-boxs">
    <div>
      <doc1
        v-if="type == 1"
        :timeStart="info.start_time"
        :timeEnd="info.end_time"
        :mobile="info.supplement.report.tel"
        :email="info.supplement.report.email"
        :storeName="info.store_name"
        :signTime="info.sign_time"
      ></doc1>
      <doc2 v-if="type == 2" :info="info"></doc2>
    </div>
  </div>
</template>
<script>
import doc1 from "@T/contract/doc/docV1.vue";
import doc2 from "@T/contract/doc/docV2.vue";
/**
 * @author lipengshuai
 * @description 监听合同看完
 * @param {Number} type 合同展示的类型 1入住合同 2附加合同
 * @param {Object} info 店铺详细信息
 * @components doc1 入住合同模板
 * @components doc2 附加合同模板
 * @event loadScroll 监听合同滚动到底部
 */
export default {
  name: "docscroll",
  props: {
    type: {
      type: Number,
      default: 1,
    },
    info: {
      type: Object,
      return() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  components: {
    doc1,
    doc2,
  },
  mounted(){ this.$nextTick(this.createScroll);},
  updated() {
    this.$nextTick(this.createScroll);
  },
  methods: {
    createScroll() {
      const el = document.getElementById("doScroll");
      el.scrollTop = 0;
      const offsetHeight = el.offsetHeight;
      el.onscroll = () => {
        let scrollTop = el.scrollTop;
        let scrollHeight = el.scrollHeight;
        // console.log(offsetHeight, scrollTop, scrollHeight);
        if (offsetHeight + scrollTop >= scrollHeight - 9) {
          // console.log("已滚动到底部");
          el.onscroll = () => {};
          this.loadScroll();
        }
      };
    },
    loadScroll() {
      this.$emit("loadScroll");
    },
  },
};
</script>
<style lang="scss" scoped>
@include b(docscroll) {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  background: #f9f9f9;
  border-radius: 4px;
  padding: 9px;
}
</style>
