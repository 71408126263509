<template>
  <div>
    <div class="header l-flexh l-flexci-s">
      <div class="header-title">甲方（平台）：</div>
      <div class="header-content">河北梦回楼兰文化传媒有限公司</div>
    </div>
    <div class="header l-flexh l-flexci-s box-m-t-12">
      <div class="header-title">乙方（商家）：</div>
      <div class="header-content">
        {{ info.store_name }}
      </div>
    </div>
    <div class="header l-flexh l-flexci-s box-m-t-12">
      <div class="header-title">签约时间：</div>
      <div class="header-content">
        {{ info.sign_time }}
      </div>
    </div>
    <p class="box-m-t-21">
      甲、乙双方经友好协商订立本补充协议，作为签约合同的有效附件，与合同具有同等法律效力。
    </p>
    <div class="header l-flexh l-flexci-s box-m-t-12">
      <div class="header-title">合作折扣：</div>
      <div class="header-content">
        {{ info.supplement.discount }}
      </div>
    </div>
    <div class="header l-flexh l-flexci-s box-m-t-12">
      <div class="header-title">未尽事宜补充：</div>
      <div class="header-content">
        {{ info.supplement.remarks }}
      </div>
    </div>
    <p class="title">乙方需要提供资料：</p>
    <p
      v-for="(item, index) in info.supplement.need_list"
      :key="'datum' + index"
    >
      {{ item }}
    </p>
    <p class="title">商家信息</p>
    <p
      v-show="item.show == 1"
      v-for="(item, index) in info.supplement.storeinfo"
      :key="index"
    >
      *{{ item.title }}:{{ item.text }}
    </p>
  </div>
</template>
<script>
/**
 * @author lipengshuai
 * @description 附加合同模板
 * @params {Object} info 店铺的详细信息
 */
export default {
  name: "doc1",
  props: {
    info: {
      type: Object,
      default() {
        return [];
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@include b(header) {
  font-size: 14px;
  color: #010101;
  @include m(title) {
    text-align: justify;
    text-align-last: justify;
    width: 98px;
    white-space: nowrap;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
  @include m(content) {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    text-decoration: underline;
  }
}
p {
  margin-top: 5px;
  font-size: 14px;
  color: #010101;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
@include b(title) {
  margin-top: 10px;
  font-family: PingFangSC-Semibold, PingFang SC !important;
  font-weight: 600 !important;
}
</style>
