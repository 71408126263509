<template>
  <div>
    <div class="header l-flexh l-flexci-s">
      <div class="header-title">甲方（平台）：</div>
      <div class="header-content">河北梦回楼兰文化传媒有限公司</div>
    </div>
    <div class="header l-flexh l-flexci-s box-m-t-12">
      <div class="header-title">乙方（商家）：</div>
      <div class="header-content">
        {{ storeName }}
      </div>
    </div>
    <div class="header l-flexh l-flexci-s box-m-t-12">
      <div class="header-title">签约时间：</div>
      <div class="header-content">
        {{ signTime }}
      </div>
    </div>
    <p class="box-m-t-21">
      甲、乙双方根据中华人民共和国法律、法规等相关规定，本着诚信、互惠、规范的原则，经友好协商，就甲方通过“微信小程序梦回楼兰平台”（以下简称平台）与乙方合作等事项，达成一致合同，双方共同遵守:
    </p>

    <p class="title">第1条 合作内容</p>
    <p>1.1 甲方提供平台服务，乙方在甲方平台发布产品及服务的优惠信息。</p>
    <p>1.2 本合同自{{ timeStart }}起至{{ timeEnd }}止。</p>
    <p>
      1.3
      本期限届满后，如甲方不再续签，应于到期后10个工作日内书面告知乙方，甲方在告知后取消乙方的在平台的用户资格等；如乙方不再续签，应于到期后10个工作日内书面告知甲方，甲方在收到乙方通知后取消乙方平台的用户资格等，如甲乙双方均未通知对方不再续签，则视为双方同意续签，本协议自动续期，续约期限与原合同期限相同，续约合同到期后，亦按照上述办法续期。。
    </p>

    <p class="title">第2条 服务费标准及结算</p>
    <p>2.1 乙方应按照本合同和平台规则的标准执行合作：</p>
    <p>
      2.2
      甲方提供平台服务，负责乙方产品推广及宣传；乙方在甲方平台提供的折扣优惠幅度，不低于其他团购平台。
    </p>
    <p>
      2.3
      乙方同意在上线产品，至少预留5%的折扣作为服务费给甲方平台，用于技术维护及运营推广服务，其余折扣全部让利给消费者，用于买单打折使用。
    </p>
    <p>
      2.4
      收款账户：乙方申请上线时，应配合甲方提交有效资料，用于开通甲方在微信服务商分账平台中的收款账户，该收款账户直接绑定乙方提供的银行卡账号，微信审核通过该收款账户信息后并由乙方完成签约操作，方可进行结算。
    </p>
    <p>
      2.5
      结算方式：用户在甲方平台消费的交易额将直接付款至乙方开通的收款账户，乙方可随时查看账单，订单交易完成后乙方当日可提现，到账周期T+1个工作日，甲方作为平台方对资金有监管权。甲方服务费直接在交易额中自动扣除。
    </p>
    <p>
      2.6 推广奖励:
      乙方推广会员使用平台消费，甲方将乙方名下所有会员服务费中40%作为商家推广奖励。
    </p>
    <p>
      2.7
      税赋：甲乙双方根据本合同开展的业务以人民币作为结算货币。乙方通过平台进行交易，所发生的费用，如交易总额，提现手续费等，应按国家标准及微信平台要求缴纳税赋。
    </p>

    <p class="title">第3条 甲方权利及义务</p>
    <p>
      3.1
      甲方根据本合同向乙方提供平台服务，在乙方申请获审核通过后7个工作日内开通服务。
    </p>
    <p>
      3.2
      乙方一旦注册成功，成为该平台的合法用户，将得到一个密码和用户名，乙方可随时更改密码。乙方将对用户名和密码安全负全部责任。另外每个用户都要对以其用户名进行的所有活动和事件负全责。乙方若发现任何非法使用用户帐户或存在安全漏洞的情况，请立即通告甲方。
    </p>
    <p>
      3.3
      甲方在消费群体统一组织安排平台整体营销推广活动，包括各种媒体的广告宣传、展览、表演、赠品等，乙方应当全力配合甲方组织、安排的整体推广活动。
    </p>
    <p>
      3.4
      甲方将对乙方进行统一的平台使用培训，乙方应积极配合推广平台使用，并按照合同约定提供折扣服务，如发现乙方不能胜任客户需求的，月度客诉超过三次以上，甲方有权终止履行本合同。
    </p>
    <p>
      3.5
      如乙方提供产品及服务出现任何安全事故，甲方有权随时告知解除本合同，并操作乙方产品下线，乙方因产品及服务安全事故所带来的损失，甲方不负任何责任。
    </p>
    <p>
      3.6
      甲方可将本合同项下的权利义务全部或部分转让给甲方指定的经营管理公司，无须征得乙方的同意，甲方仅需通知乙方。
    </p>
    <p>
      3.7
      甲乙双方合同生效期内，甲方有权随时自主变更项目或项目内任何部分的名称，但甲方应在相关政府部门核准新名称后通知乙方。甲方无须就项目或项目内任何部分的名称变更而对乙方作出任何赔偿或补偿。
    </p>

    <p class="title">第4条 乙方权利及义务</p>
    <p>4.1 乙方可免费享受甲方提供的VR拍摄服务一次，VR版权归甲方所有。</p>
    <p>4.2 乙方与平台合作后将得到甲方提供的推广补贴劵，用于门店促销及引流。</p>
    <p>4.3 乙方将有机会参与平台的商圈直播互动宣传及限时秒杀活动。</p>
    <p>4.4 乙方可享受甲方定期活动策划扶持。</p>
    <p>4.5 乙方可申请在平台首页的广告宣传或优先活动冠名。</p>
    <p>
      4.6
      乙方需提供合法的资质证明复印件，包括工商登记证明、税务登记证明、卫生许可证等相关资质及法人身份证，因乙方资质问题使本协议无法正常履行的结果由乙方负责。
    </p>
    <p>
      4.7
      乙方已经在平台上线的产品优惠信息，在双方规定的上线时间内，未经甲方同意不得进行任何形式直接或间接的取消或变更，如因特殊原因甲方确实需要进行变更或取消的，由乙方至少提前5个工作日告知甲方，并由双方另行商议对页面做相应的调整后方可进行变更。
    </p>
    <p>
      4.8
      乙方有义务提供合作期间在甲方平台营销活动和品牌形象展示所需要的所有文字、图片和视频等资料以供甲方创作，乙方保证对其提供的资料具有完全权利，并有权授权给甲方基于本协议目的进行使用。
    </p>
    <p>4.9 如甲方用户要求开具发票，由乙方负责提供。</p>
    <p>
      4.10甲方用户退订情形，在乙方店铺线下买单情况，乙方应该在接到用户退订申请时立即处理，用户在线上购买产品时，甲方应在收到退订申请后告知乙方取消。乙方应据甲方通知做相应调整。
    </p>
    <p>
      4.11
      乙方需要每日对平台流水进行账目核对，关于账目的任何疑义须在24小时内向甲方提出申请，甲方将沟通解决，如当日未提出申请视为账单无误，因此造成的损失由乙方自行承担。
    </p>
    <p>
      4.12
      乙方实体店铺经营发生重大变化，影响会员消费打折，应及时告知甲方，由甲方告知会员。
    </p>
    <p>
      4.13
      甲方会员只有按照平台规则，使用人生券，乙方才可按照其在本平台的宣传，给予优惠或折扣，乙方不得与会员绕过本平台私下消费结算。
    </p>

    <p class="title">第5条 合作约定</p>
    <p>5.1 双方的品牌归各自所有。</p>
    <p>
      5.2
      甲、乙双方均不可在对方未经授权的情况下使用对方名称、LOGO以及涉及相关内容的销售、促销宣传材料及广告、报纸、杂志、宣传单等。如因此产生的不良后果及损失由违约方自行全部承担。
    </p>
    <p>
      5.3
      任何双方违反本协议所规定的义务导致另一方受到包括但不限于投诉、举报、诉讼或处罚、赔偿等损失，违约方在收到守约方要求纠正其违法行为的书面通知之日，应立即停止其违约行为，并在10日内赔偿守约方因此受到的损失。
    </p>
    <p>
      5.4
      本协议任何一方均应对其获得对方的业务、经营、财务状况和其他保密资料予以严格保密，不得以任何形式向任何第三方披露。如因任何一方未能履行保密义务而给其他方造
      成任何损失的，应承担赔偿责任。
    </p>
    <p>
      5.5
      甲方可提供促销、引流、推广等增值服务。乙方如需定制，需支付相应费用，具体须签订补充协议。
    </p>
    <p>
      5.8
      甲方运营商、代理商、城市合伙人等无权向乙方收取任何费用，如乙方私自向甲方运营商、代理商、城市合伙人等付费，产生纠纷，与甲方无关。
    </p>

    <p class="title">第6条 违约责任</p>
    <p>
      6.1
      如一方发生违约行为，守约方可以书面通知方式要求违约方在指定的合理时限内停止违约行为，并就违约行为造成的损失进行索赔，如违约方未能按时停止违约行为，则守约方有权立即终止本合同。
    </p>
    <p>
      6.2
      乙方违背诚信原则，如利用或绕过“微信小程序梦回楼兰平台”私自与客户进行交易（飞单）、通过虚假交易等形式从“微信小程序梦回楼兰平台”获得营销资源、款项等利益、协助他人获取不正当利益等或有其他违约情形，甲方有权注销乙方上线门店及会员。
    </p>
    <p>6.3 乙方违反保密合同给甲方造成损失的。</p>
    <p>6.4 其他由”微信小程序梦回楼兰平台”规则规定的违约情形和处罚规则。</p>
    <p>
      6.5
      甲方会员持人生券在乙方消费结算时，如乙方不认可甲方人生券的优惠打折功能，则甲方有权立即解除本合同，取消乙方在梦回楼兰平台的用户资格，注销乙方上线门店及会员。
    </p>
    <p>
      6.6
      乙方违反上述任何一项的，若本合同中对违约已经有约定的，则按照条款的约定执行外，乙方还应对其给甲方造成的包括但不仅限于诉讼费、律师费、赔偿费、保全费、补偿、行政机关处罚、公告费、评估费、鉴定费、拍卖费、差旅费以及品牌、商誉损失等进行赔偿。
    </p>

    <p class="title">第7条 免责条款</p>
    <p>
      不论在何种情况下，甲方均不对由于电力、网络、电脑、通讯或其他系统的故障、罢工（含内部罢工或劳工骚乱）、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、战争、政府行为等不可抗力，国际、国内法院的命令或第三方问题以及其他非甲方过错而造成的不能服务或延迟服务等情形承担责任。
    </p>

    <p class="title">第8条 保密责任</p>
    <p>
      8.1
      任何一方对在本合同履行过程中，均对对方的商业秘密或其他产品、服务、技术、经营信息负有相应地保密义务，双方均不得向任何其他第三方泄露。
    </p>
    <p>8.2 本合同有效期内及终止后，本保密条款仍具有法律效力。</p>

    <p class="title">第9条 廉政条款</p>
    <p>
      9.1
      在本协议的签订和履行过程中，乙方不得采取任何方式贿赂或唆使甲方工作人员损害甲方利益或增加乙方利益，否则将终止合作，注销乙方上线门店及会员。
    </p>
    <p>
      9.2
      在本协议的签订和履行过程中，乙方对甲方员工不得有任何超过正常工作交往标准的招待或宴请行为，亦不得有送礼或给予回扣、佣金、有价证券、实物或其他形式的好处的行为，否则即视为乙方违反本条款。
    </p>
    <p>
      9.3
      甲方提倡廉洁自律的企业文化，若甲方员工存在违反国家或公司廉政反腐相关规定的行为，乙方可向甲方总部调查机构（市场部）举报，受理电话：<a
        :href="'tel:' + mobile"
        >{{ mobile }}</a
      >
      电子邮箱：<a :href="'mailto' + email">{{ email }}</a>
    </p>

    <p class="title">第10条 争议的解决</p>
    <p>
      若因本合同的履行发生争议的，甲乙双方应友好协商解决，协商不成的，甲乙双方一致同意提交廊坊仲裁委员会进行裁决，该裁决为一裁终局。
    </p>

    <p class="title">第11条 其他</p>
    <p>
      11.1
      有效通知：甲方对于乙方所有的通知均可通过”微信小程序梦回楼兰平台”公告、电子邮件、客户端消息、手机短信、邮件、传真或常规的信件传送至本合同指定处等方式进行。
    </p>
    <p>
      11.2
      甲乙双方确认不是雇佣与被雇佣关系，更不是劳动关系，而是合作关系，甲乙双方不具有劳动合同关系。
    </p>
    <p>
      11.3
      本合同一式贰份，双方各持壹份，本合同附件和本合同不可分割的一部分，和合同正文具有相同法律效力。
    </p>
  </div>
</template>
<script>
/**
 * @author lipengshuai
 * @description 合同入住文档模板
 * @param {String} storeName 店铺名称
 * @param {String} signTime 签约时间
 * @param {String} timeStart 续签合同开始时间
 * @param {String} timeEnd 续签合同结束是时间
 * @param {String} mobile 梦回楼兰联系方式 手机号
 * @param {String} email 梦回楼兰联系方式 邮箱
 */
export default {
  name: "doc1",
  props: {
    storeName: {
      type: String,
      default: "",
    },
    signTime: {
      type: String,
      default: "",
    },
    timeStart: {
      type: String,
      default: "",
    },
    timeEnd: {
      type: String,
      default: "",
    },
    mobile: {
      type: String,
      default: "18034269379",
    },
    email: {
      type: String,
      default: "rsck2020@163.com",
    },
  },
};
</script>
<style lang="scss" scoped>
@include b(header) {
  font-size: 14px;
  color: #010101;
  @include m(title) {
    text-align: justify;
    text-align-last: justify;
    width: 98px;
    white-space: nowrap;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
  @include m(content) {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    text-decoration: underline;
  }
}
@include b(body) {
  @include m(content) {
  }
}
p {
  margin-top: 5px;
  font-size: 14px;
  color: #010101;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
@include b(title) {
  margin-top: 10px;
  font-family: PingFangSC-Semibold, PingFang SC !important;
  font-weight: 600 !important;
}
</style>
