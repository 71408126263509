<template>
  <div class="l-container l-boxs l-flexv l-flexci-c">
    <image class="status" src="@STATIC/"></image>
    <div class="head l-flex-s0 l-flexh l-flexci-c">
      <span>
        <template v-if="type == 1">商家入驻合同</template>
        <template v-if="type == 2">附加合同</template>
      </span>
      <span @click="changeType">
        <template v-if="type == 1">前往签署附加合同</template>
        <template v-if="type == 2">前往签署商家入驻合同</template>
      </span>
    </div>
    <div class="body">
      <docscroll
        :type="type"
        :info="contractInfo"
        @loadScroll="changeRead"
      ></docscroll>
    </div>
    <div class="l-flex-s0">
      <foot
        :isPost="isBtn"
        :isRead="type == 1 ? isRead1 : isRead2"
        :signUrl="signUrl"
        :signTime="contractInfo.sign_time"
        :btnText="btnText"
        @onSignTouch="handleSinginTouch"
        @onClick="onSubmit"
      ></foot>
    </div>
    <nut-popup v-model="signShow">
      <signature
        v-if="signShow"
        strokeStyle="red"
        @confirm="signConfirm"
        @clear="signClear"
      ></signature>
    </nut-popup>
  </div>
</template>
<script>
var imgData =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQ0AAACiCAYAAABbL5iHAAAAAXNSR0IArs4c6QAABFVJREFUeF7t1LENAAAIwzD6/9PckN3MnSyUnSNAgEAQWNiaEiBA4ETDExAgkAREI3EZEyAgGn6AAIEkIBqJy5gAAdHwAwQIJAHRSFzGBAiIhh8gQCAJiEbiMiZAQDT8AAECSUA0EpcxAQKi4QcIEEgCopG4jAkQEA0/QIBAEhCNxGVMgIBo+AECBJKAaCQuYwIERMMPECCQBEQjcRkTICAafoAAgSQgGonLmAAB0fADBAgkAdFIXMYECIiGHyBAIAmIRuIyJkBANPwAAQJJQDQSlzEBAqLhBwgQSAKikbiMCRAQDT9AgEASEI3EZUyAgGj4AQIEkoBoJC5jAgREww8QIJAERCNxGRMgIBp+gACBJCAaicuYAAHR8AMECCQB0UhcxgQIiIYfIEAgCYhG4jImQEA0/AABAklANBKXMQECouEHCBBIAqKRuIwJEBANP0CAQBIQjcRlTICAaPgBAgSSgGgkLmMCBETDDxAgkAREI3EZEyAgGn6AAIEkIBqJy5gAAdHwAwQIJAHRSFzGBAiIhh8gQCAJiEbiMiZAQDT8AAECSUA0EpcxAQKi4QcIEEgCopG4jAkQEA0/QIBAEhCNxGVMgIBo+AECBJKAaCQuYwIERMMPECCQBEQjcRkTICAafoAAgSQgGonLmAAB0fADBAgkAdFIXMYECIiGHyBAIAmIRuIyJkBANPwAAQJJQDQSlzEBAqLhBwgQSAKikbiMCRAQDT9AgEASEI3EZUyAgGj4AQIEkoBoJC5jAgREww8QIJAERCNxGRMgIBp+gACBJCAaicuYAAHR8AMECCQB0UhcxgQIiIYfIEAgCYhG4jImQEA0/AABAklANBKXMQECouEHCBBIAqKRuIwJEBANP0CAQBIQjcRlTICAaPgBAgSSgGgkLmMCBETDDxAgkAREI3EZEyAgGn6AAIEkIBqJy5gAAdHwAwQIJAHRSFzGBAiIhh8gQCAJiEbiMiZAQDT8AAECSUA0EpcxAQKi4QcIEEgCopG4jAkQEA0/QIBAEhCNxGVMgIBo+AECBJKAaCQuYwIERMMPECCQBEQjcRkTICAafoAAgSQgGonLmAAB0fADBAgkAdFIXMYECIiGHyBAIAmIRuIyJkBANPwAAQJJQDQSlzEBAqLhBwgQSAKikbiMCRAQDT9AgEASEI3EZUyAgGj4AQIEkoBoJC5jAgREww8QIJAERCNxGRMgIBp+gACBJCAaicuYAAHR8AMECCQB0UhcxgQIiIYfIEAgCYhG4jImQEA0/AABAklANBKXMQECouEHCBBIAqKRuIwJEBANP0CAQBIQjcRlTICAaPgBAgSSgGgkLmMCBETDDxAgkAREI3EZEyAgGn6AAIEkIBqJy5gAAdHwAwQIJAHRSFzGBAiIhh8gQCAJiEbiMiZAQDT8AAECSUA0EpcxAQKi4QcIEEgCopG4jAkQEA0/QIBAEhCNxGVMgIBo+AECBJKAaCQuYwIEHq/OAKP+bpORAAAAAElFTkSuQmCC";

import docscroll from "@T/contract/doscroll/docscrollV1.vue";
import foot from "@T/contract/foot/footV1.vue";
import signature from "@T/contract/sign/signatureV1.vue";
import { mapActions, mapState } from "vuex";
export default {
  needVuex: true,
  configVuex: ["contract_renew"],
  name: "ContractRenew",
  components: {
    docscroll,
    foot,
    signature,
  },
  data() {
    return {
      signShow: false, //是否展示签名
      signUrl: "", //默认的签名连接为空
      type: 1, //1 入驻合同 2 附加合同
      isRead1: false, //是否已经读了入驻合同
      isRead2: false, //是否已经读了附加合同
    };
  },
  created() {
    document.title = "梦回楼兰";
    this.getReNewInfo()
      .then((res) => {
        // console.log("请求成功", res);
      })
      .catch((err) => {
        // console.log("请求失败", err);
      });
  },
  computed: {
    ...mapState("contract_renew", { contractInfo: (state) => state.reNewInfo }),
    isPost() {
      if (!this.isRead1) {
        return false;
      }
      if (!this.isRead2) {
        return false;
      }
      if (this.contractInfo.second_party == "") {
        return false;
      }
      if (this.contractInfo.second_appendix_party == "") {
        return false;
      }
      return true;
    },
    btnText() {
      if (this.isPost) {
        return "确定";
      }
      if (this.isRead1 && this.contractInfo.second_party != "") {
        return "下一步";
      }
      if (this.isRead2 && this.contractInfo.second_appendix_party != "") {
        return "下一步";
      }
      return "确定";
    },
    isBtn() {
      if (
        this.isRead1 &&
        this.contractInfo.second_party != "" &&
        this.type == 1
      ) {
        return true;
      }
      if (
        this.isRead2 &&
        this.contractInfo.second_appendix_party != "" &&
        this.type == 2
      ) {
        return true;
      }

      return false;
    },
  },
  watch: {
    type() {
      if (this.type == 1) {
        this.signUrl = this.contractInfo.second_party; //入驻合同签名url
      }
      if (this.type == 2) {
        this.signUrl = this.contractInfo.second_appendix_party; //附加合同签名url
      }
    },
  },
  methods: {
    ...mapActions("contract_renew", ["getReNewInfo"]),
    changeRead() {
      if (this.type == 1) {
        this.isRead1 = true;
      }
      if (this.type == 2) {
        this.isRead2 = true;
      }
    },
    changeType() {
      if (this.type == 1) {
        this.type = 2;
      } else if (this.type == 2) {
        this.type = 1;
      }
    },
    onSubmit() {
      let message = "";
      let showDialog = false;
      if (this.isBtn && !this.isPost) {
        if (this.type == 1) {
          this.type = 2;
        } else if (this.type == 2) {
          this.type = 1;
        }
        return;
      }
      if (this.type == 1) {
        if (!this.isRead1) {
          showDialog = true;
          message = "您还没有阅读入驻合同内容！";
        } else if (this.contractInfo.second_party == "") {
          showDialog = true;
          message = "您的入驻合同还未签字！"; //入驻合同签名url
        } else if (!this.isRead2) {
          showDialog = true;
          message = "您还没有阅读附加合同内容！";
        } else if (this.contractInfo.second_appendix_party == "") {
          showDialog = true;
          message = "您的附加合同还未签字！";
        }
      } else {
        if (!this.isRead2) {
          showDialog = true;
          message = "您还没有阅读附加合同内容！";
        } else if (this.contractInfo.second_appendix_party == "") {
          showDialog = true;
          message = "您的附加合同还未签字！";
        } else if (!this.isRead1) {
          showDialog = true;
          message = "您还没有阅读入驻合同内容！";
        } else if (this.contractInfo.second_party == "") {
          showDialog = true;
          message = "您的入驻合同还未签字！"; //入驻合同签名url
        }
      }

      if (showDialog) {
        this.$dialog({
          title: "温馨提示",
          content: message,
          noCancelBtn: true,
          closeOnClickModal: false,
          onOkBtn() {
            //确定按钮点击事件
            this.close(); //关闭对话框
          },
        });
        return;
      }
      this.$utils.debounce(async () => {
        this.toast = this.$toast.loading("合同上传中...");
        try {
          let query = await this.$api.contract.renew.makesure({
            id: this.contractInfo.id,
            user_id: this.common_platform.header["user-id"],
            baseinfo: this.contractInfo.second_party,
            second_appendix_party: this.contractInfo.second_appendix_party,
          });
          this.toast.hide();
          console.log("提交表单", query);
          if (query.data.error == 0) {
            this.$dialog({
              title: "合同上传成功",
              content: "请您耐心等待审核！",
              noCancelBtn: true,
              closeOnClickModal: false,
              onOkBtn() {
                //确定按钮点击事件
                this.close(); //关闭对话框
                this.$platform.wxsdk.wxRoute({ type: "navigateBack" });
              },
            });
          } else {
            this.$dialog({
              title: "合同上传失败",
              content: query.data.message,
              noCancelBtn: true,
              closeOnClickModal: false,
              onOkBtn() {
                //确定按钮点击事件
                this.close(); //关闭对话框
              },
            });
          }
        } catch (error) {
          //
          this.$dialog({
            title: "网络错误",
            content: "合同上传失败",
            noCancelBtn: true,
            closeOnClickModal: false,
            onOkBtn() {
              //确定按钮点击事件
              this.close(); //关闭对话框
            },
          });
        }
      });
    },
    signConfirm(canvas, data) {
      // console.log(data);
      this.signShow = false;
      if (imgData == data || data == "") {
        //没有签名
        return;
      }
      this.signUrl = data;
      if (this.type == 1) {
        this.$vuex.contract_renew("reNewInfo.second_party", data); //入驻合同签名url
      } else if (this.type == 2) {
        this.$vuex.contract_renew("reNewInfo.second_appendix_party", data); //附加合同签名url
      }
    },
    signClear() {
      if (this.type == 1) {
        this.$vuex.contract_renew("reNewInfo.second_party", ""); //入驻合同签名url
      } else if (this.type == 2) {
        this.$vuex.contract_renew("reNewInfo.second_appendix_party", ""); //附加合同签名url
      }
    },
    handleSinginTouch() {
      this.signShow = true;
    },
  },
};
</script>
<style scoped lang="scss">
@include b(l-container) {
  padding: 0 18px;
}
@include b(status) {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
@include b(head) {
  width: 100%;
  height: 72px;
  & > span {
    &:first-of-type {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #010101;
    }
    &:last-of-type {
      margin-left: 14px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f46a17;
    }
  }
}
@include b(body) {
  width: 100%;
  height: calc(100% - 72px - 179px);
}
@include b(foot) {
}
</style>
