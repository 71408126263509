<template>
  <div class="foot l-pr l-flexv l-flexci-c">
    <div class="head l-flexh l-flexci-c l-flexm-b box-m-t-14">
      <span :class="{ 'head-active': isRead }">合同事宜已浏览</span>
      <span class="head-active" @click="onSignTouch">点击签字</span>
    </div>
    <div class="l-width-fill l-flexh box-m-t-7 l-pr">
      <div class="label l-flex-g1 l-flexh l-flexci-c l-flexm-b box-m-t-13">
        <span>甲方：梦回楼兰</span>
        <span>乙方：</span>
      </div>
      <span class="signature" @click="onSignTouch">
        <img v-if="imgUrl" :src="imgUrl" />
      </span>
    </div>
    <div class="l-width-fill time l-flexh l-flexci-c l-flexm-b box-m-t-9">
      <span>{{ signTime }}</span>
      <span>{{ signTime }}</span>
    </div>
    <button class="btn" :class="{ 'btn-active': isPost }" @click="onClick">
      {{ btnText }}
    </button>
  </div>
</template>
<script>
/**
 * @author lipengshuai
 * @description 签合同底部公共样式
 * @param {String} isRead 是否看了合同
 * @param {String} signUrl 签名生成的图片
 * @param {String} signTime 签名时间
 * @param {String} isPost 是否可以提交
 * @param {String} btnText 按钮文本
 * @event onClick 点击了提交按钮
 * @event onSignTouch 点击续签按钮
 */
export default {
  name: "foot",
  props: {
    //是否看了合同
    isRead: {
      type: Boolean,
      default: false,
    },
    //签名
    signUrl: {
      type: String,
      default: "",
    },
    //签署时间
    signTime: {
      type: String,
      default: "",
    },
    //是否可以提交
    isPost: {
      type: Boolean,
      default: false,
    },
    //按钮文本
    btnText: {
      type: String,
      default: "确定",
    },
  },
  data() {
    return {
      imgUrl: "",
    };
  },
  watch: {
    signUrl() {
      this.imgUrl = this.$props.signUrl;
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
    onSignTouch() {
      //点击签字
      this.$emit("onSignTouch");
    },
  },
};
</script>
<style lang="scss" scoped>
@include b(foot) {
  width: 100%;
  height: 179px;
}
@include b(head) {
  width: 100%;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #939393;
  & > span:last-of-type {
    font-size: 14px;
  }
  @include m(active) {
    color: #f46a17 !important;
  }
}
@include b(signature) {
  width: 83px;
  height: 33px;
  background: #fefdf9;
  border-radius: 2px;
  border: 1px solid #e3d8ac;
  & > img {
    width: 100%;
    height: 100%;
  }
}
@include b(label) {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
@include b(time) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
@include b(btn) {
  margin-top: 26px;
  border: none;
  width: 339px;
  height: 44px;
  background: #d1d1d1;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  @include m(active) {
    background: linear-gradient(
      125deg,
      #fab476 0%,
      #eb6100 100%,
      #eb6100 100%
    ) !important;
  }
}
</style>
