<template>
  <div class="signature" :class="customClass">
    <!-- <canvas id="rotate" :height="canvasHeight" :width="canvasWidth"></canvas> -->
    <div class="signature-inner" ref="wrap">
      <canvas
        ref="canvas"
        :height="canvasHeight"
        :width="canvasWidth"
        v-if="isCanvasSupported"
      ></canvas>
      <!-- style="transform: scale(0.2)" -->
      <canvas
        id="outCanvas"
        style="display: none"
        :height="canvasWidth * 1"
        :width="canvasHeight * 1"
        v-if="isCanvasSupported"
      >
      </canvas>
      <p class="nut-signature-unsopport" v-else>{{ unSupportTpl }}</p>
    </div>
    <slot></slot>
    <div class="foot">
      <button class="btn btn-piercing" @click="clear()">
        <span>重</span>
        <span>签</span>
      </button>
      <button class="btn btn-fill" @click="confirm()">
        <span>确</span>
        <span>认</span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "signature",
  props: {
    customClass: {
      type: String,
      default: "",
    },
    lineWidth: {
      type: Number,
      default: 6,
    },
    strokeStyle: {
      type: String,
      default: "#333",
    },
    type: {
      type: String,
      default: "png",
    },
    unSupportTpl: {
      type: String,
      default: "对不起，当前浏览器不支持Canvas，无法使用本控件！",
    },
  },
  data() {
    return {
      canvasHeight: 0,
      canvasWidth: 0,
      ctx: null,
      isSupportTouch: "ontouchstart" in window,
      events:
        "ontouchstart" in window
          ? ["touchstart", "touchmove", "touchend", "touchleave"]
          : ["mousedown", "mousemove", "mouseup", "mouseleave"],
    };
  },
  components: {},
  computed: {
    isCanvasSupported() {
      let elem = document.createElement("canvas");
      return !!(elem.getContext && elem.getContext("2d"));
    },
  },

  methods: {
    addEvent() {
      (this.startEventHandler = this.startEventHandler.bind(this)),
        this.$refs.canvas.addEventListener(
          this.events[0],
          this.startEventHandler,
          false
        );
    },

    startEventHandler(event) {
      event.preventDefault();

      this.ctx.beginPath();
      this.ctx.lineWidth = this.lineWidth;
      this.ctx.strokeStyle = this.strokeStyle;
      (this.moveEventHandler = this.moveEventHandler.bind(this)),
        (this.leaveEventHandler = this.leaveEventHandler.bind(this)),
        (this.endEventHandler = this.endEventHandler.bind(this));

      this.$refs.canvas.addEventListener(
        this.events[1],
        this.moveEventHandler,
        false
      );
      this.$refs.canvas.addEventListener(
        this.events[2],
        this.endEventHandler,
        false
      );
      this.$refs.canvas.addEventListener(
        this.events[3],
        this.leaveEventHandler,
        false
      );
    },

    moveEventHandler(event) {
      event.preventDefault();

      let evt = this.isSupportTouch ? event.touches[0] : event;
      let coverPos = this.$refs.canvas.getBoundingClientRect();
      // let mouseX = evt.clientX - coverPos.left;
      // let mouseY = evt.clientY - coverPos.top;
      // this.ctx.lineTo(mouseX, mouseY);
      let mouseX = evt.clientX - coverPos.left;
      let mouseY = evt.clientY - coverPos.top;
      this.ctx.lineTo(mouseX, mouseY);
      this.ctx.stroke();
    },

    endEventHandler(event) {
      event.preventDefault();

      this.$refs.canvas.removeEventListener(
        this.events[1],
        this.moveEventHandler,
        false
      );
      this.$refs.canvas.removeEventListener(
        this.events[2],
        this.endEventHandler,
        false
      );
    },
    leaveEventHandler(event) {
      event.preventDefault();
      this.$refs.canvas.removeEventListener(
        this.events[1],
        this.moveEventHandler,
        false
      );
      this.$refs.canvas.removeEventListener(
        this.events[2],
        this.endEventHandler,
        false
      );
    },
    clear(isUnEmit) {
      this.$refs.canvas.addEventListener(
        this.events[2],
        this.endEventHandler,
        false
      );
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      this.ctx.closePath();
      if (!isUnEmit) {
        this.$emit("clear");
      }
    },

    confirm() {
      this.onSave();
    },

    onSave() {
      let canvas = this.$refs.canvas;

      let dataurl = "";
      let outCanvas = document.getElementById("outCanvas");
      let outCtx = outCanvas.getContext("2d");
      let baseW = this.canvasWidth;
      let baseH = this.canvasHeight;
      let radio = 0.8;
      // outCtx.drawImage(this.$refs.canvas, 0, 0, baseW * 2, baseH * 2);
      outCtx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      outCtx.closePath();
      outCtx.imageSmoothingEnabled = false;
      outCtx.rotate(-90 * (Math.PI / 180));
      outCtx.translate(-baseW * radio, 0);
      outCtx.drawImage(this.$refs.canvas, 0, 0, baseW * radio, baseH * radio);
      var imgData = outCtx.getImageData(0,0,this.canvasWidth, this.canvasHeight);
      var data = imgData.data;
      let isupdata=0;
      for(var i = 0;i<data.length;i+=4){
          isupdata+=data[i]+data[i+1]+data[i+2]+data[i+3];
      }
  
      switch (this.type) {
        case "png":
          dataurl = outCanvas.toDataURL("image/png", 1);
          break;
        case "jpg":
          dataurl = outCanvas.toDataURL("image/jpeg", 0.8);
          break;
        default:
          break;
      }
      if(isupdata==0){
        dataurl=""
      }
      // return;
      this.clear(true);
      this.$emit("confirm", canvas, dataurl);
    },
  },

  mounted() {
    if (this.isCanvasSupported) {
      this.ctx = this.$refs.canvas.getContext("2d");
      this.canvasWidth = this.$refs.wrap.offsetWidth;
      (this.canvasHeight = this.$refs.wrap.offsetHeight), this.addEvent();
    }
  },
};
</script>
<style lang="scss" scoped>
@include b(signature) {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 10px;
  display: flex;
  flex-direction: row-reverse;

  @include m(inner) {
    position: relative;
    background: rgb(217, 255, 0);
    $baseW: 83px;
    $baseH: 33px;
    $baseR: 7;
    // width: calc(332px * 2);
    // width: 332px;
    // height: calc(132px * 2);
    width: $baseH * $baseR;
    height: $baseW * $baseR;
    border: 1px solid #f46a17;
  }
}
@include b(foot) {
  // margin: 20px 0;
  // width: auto;
  // height: 44px;
  margin: 0 20px;
  width: 44px;
  // align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
@include b(btn) {
  box-sizing: border-box;
  // width: 130px;
  // height: 44px;
  height: 130px;
  width: 44px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  & > span {
    transform: perspective(1000);
    transform: rotate(90deg);
  }
  @include m(piercing) {
    border: 1px solid #f46a17;
    color: #f46a17;
    background: white;
  }
  @include m(fill) {
    border: none !important;
    color: #fff;
    background: linear-gradient(125deg, #fab476 0%, #eb6100 100%, #eb6100 100%);
  }
}
</style>
